<template>
  <div class="container">
    <div class="header">
      <Title :title="$t('bc33a08')" :no-border="true" />
      <div class="tabs">
        <div
          v-for="(item, index) of tabList"
          :key="index"
          class="tabPane"
          :class="{ active: searchData.category === item.value }"
          @click="clickTab(item.value)"
        >
          <el-badge is-dot :hidden="hideDot(item.value)">
            {{ $t(item.name) }}
          </el-badge>
        </div>
      </div>
      <ContentFilter
        :search-data="searchData"
        @submit="handleSubmit"
        @reset="handleReset"
      />
    </div>
    <div v-loading="loading" class="tableList">
      <Title :title="this.$t('43bb582')" />
      <el-table
        ref="multipleTable"
        class="tableContainer"
        :data="tableData"
        header-cell-class-name="headerCellClass"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @cell-click="handleCellClick"
      >
        <el-table-column type="selection" width="50" />
        <el-table-column
          width="80"
          :label="$t('285e24a')"
          :formatter="(_, __, ___, i) => (query.page - 1) * query.size + i + 1"
        />
        <el-table-column :label="$t('dcce879')" show-overflow-tooltip>
          <template slot-scope="scope">
            <i v-if="!scope.row.readed" class="dot"></i>
            <span class="content">{{ scope.row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('5237d0d')"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.readed" class="readed">{{
              $t("8d0495e")
            }}</span>
            <span v-else class="readed active">{{ $t("45564ca") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="this.$t('a9065eb')"
          show-overflow-tooltip
        />
      </el-table>
      <div style="padding-top: 30px; padding-bottom: 40px">
        <!-- <el-button @click="toggleSelection([tableData[1], tableData[2]])">
          切换第二、第三行的选中状态
        </el-button> -->
        <div class="sign" @click="toggleSelection()">
          {{ this.$t("3206cb1") }}
        </div>
        <Pagination
          class="pagination"
          :page-info="query"
          @changePage="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Title from "@/components/Title";
import Pagination from "@/components/Pagination";
import Api from "@/api/userCenter";
// import dayjs from 'dayjs'
import ContentFilter from "./components/filter";
export default {
  name: "System",
  components: {
    Title,
    ContentFilter,
    Pagination,
  },
  data() {
    return {
      loading: false,
      searchData: {
        category: "SERVICE",
        readed: "",
        timeLimit: [],
      },
      tabList: [
        {
          name: "d6d9e22",
          value: "SERVICE",
        },
        {
          name: "249393c",
          value: "CONSULTING",
        },
        {
          name: "cf2c0c6",
          value: "SYSTEM",
        },
      ],
      query: {
        page: 1,
        size: 10,
        total: 0,
      },
      tableData: [],
      multipleSelection: [],
    };
  },
  computed: {
    ...mapGetters(["unreadMessage"]),
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions(["getUnread"]),
    // handleCellClass({ row, column, rowIndex, columnIndex }) {
    //   if (true) {
    //     return 'cellReaded'
    //   }
    // },
    hideDot(type) {
      switch (type) {
        case "SERVICE":
          return !this.unreadMessage.serviceMessage;
        case "CONSULTING":
          return !this.unreadMessage.consultingMessage;
        case "SYSTEM":
          return !this.unreadMessage.systemMessage;
      }
    },
    changePage(val) {
      this.query.page = val;
      this.getData();
    },
    alertMsg(row) {
      this.$alert(row.content, "系统信息", {
        showConfirmButton: false,
        showCancelButton: false,
        callback: (action) => {
          // this.$message({
          //   type: 'info',
          //   message: `action: ${action}`,
          // })
        },
      });
    },
    go(path) {
      this.$router.push({
        path,
      });
    },
    handleCellClick(row, column, cell, event) {
      if (column.label === "内容") {
        const category = this.searchData.category;
        const type = row.type;
        const id = row.id;
        const url = row.url;
        const typeId = row.typeId;
        if (typeId) {
          if (category === "SYSTEM") {
            this.alertMsg(row);
          } else {
            switch (type) {
              case "SERVICE_PUBLISH_MESSAGE": {
                if (row.status) {
                  this.$message.warning("该服务已删除");
                } else this.go(`/content/service/detail?id=${typeId}`);
                break;
              }
              case "SERVICE_MESSAGE": {
                if (row.status) {
                  this.$message.warning("该服务已删除");
                } else
                  this.go(`/content/service/cooperationDetail?id=${typeId}`);
                break;
              }
              case "REQUIREMENTS_USER": {
                if (row.status) {
                  this.$message.warning("该需求已删除");
                } else this.go(`/content/request/detail?id=${typeId}`);
                break;
              }
              case "REQUIREMENTS_ORGANIZATION": {
                if (row.status) {
                  this.$message.warning("该需求已删除");
                } else
                  this.go(`/content/request/cooperation_detail?id=${typeId}`);
                break;
              }
              case "LAW_SEARCH_APPLY_MESSAGE": {
                if (row.status) {
                  this.$message.warning("该查询已删除");
                } else this.go(`/content/lookup/detail?id=${typeId}`);
                break;
              }
              case "CONSULTING_MESSAGE": {
                if (row.status) {
                  this.$message.warning("该咨询已删除");
                } else this.go(`/content/consult/detail?id=${typeId}`);
                break;
              }
            }
          }
          if (!row.readed) {
            this.changeSystemMsgReaded(id);
          }
        } else if (type === "COMPANY_REPORT") {
          this.handleReport(url);
        } else {
          this.$message.error("typeId不能为空");
        }
      }
    },
    handleReport(url) {
      window.open(url);
    },
    toggleSelection(rows) {
      const selection = this.$refs.multipleTable.selection;
      if (selection.length) {
        const ids = [];
        for (let index = 0; index < selection.length; index++) {
          const element = selection[index];
          if (!element.readed) {
            ids.push(element.id);
          }
        }
        this.changeSystemMsgReaded(ids.join());
      }
    },
    changeSystemMsgReaded(ids) {
      if (ids) {
        Api.changeSystemMsgReaded(ids)
          .then((res) => {
            this.getData();
          })
          .catch((e) => {});
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSearchData() {
      const info = this.searchData;
      const data = {
        category: info.category,
        startTime: info.timeLimit[0],
        endTime: info.timeLimit[1],
        readed: info.readed,
        page: this.query.page,
        size: this.query.size,
      };
      return data;
    },
    getData() {
      this.loading = true;
      const data = this.handleSearchData();
      this.getUnread();
      Api.getSystemMsgList(data)
        .then((res) => {
          this.handleRes(res);
        })
        .catch((e) => {})
        .then(() => {
          this.loading = false;
        });
    },
    handleRes(res) {
      console.log("res", res);
      this.query.total = res.page.total;
      this.tableData = res.content;
    },
    clickTab(val) {
      this.searchData.category = val;
      this.query = {
        page: 1,
        size: 10,
        total: 0,
      };
      this.getData();
    },
    handleSubmit() {
      this.getData();
    },
    handleReset() {
      this.searchData = {
        category: "SERVICE",
        readed: "",
        timeLimit: [],
      };
      this.query = {
        page: 1,
        size: 10,
        total: 0,
      };
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.container {
  width: 920px;
  margin: 30px auto;
  .header {
    background: #fff;
    .tabs {
      display: flex;
      padding: 13px 30px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      border-bottom: 1px solid #eee;
      .tabPane {
        position: relative;
        margin-right: 40px;
        cursor: pointer;
      }
      .active {
        font-weight: 500;
        color: @uni-text-color-select;
        &::after {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -13px;
          content: "";
          width: 100%;
          height: 2px;
          background: @uni-text-color-select;
        }
      }
    }
  }

  .tableList {
    margin-top: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 22px 0px rgba(223, 229, 235, 0.5);
    border-radius: 4px;
    .tableContainer {
      /deep/ .headerCellClass {
        font-size: 13px;
        font-weight: bold;
        color: #424242;
      }
      /deep/ .active {
        color: #f5222d;
      }
      /deep/ .dot {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-right: 5px;
        background: @uni-text-color-select;
      }
      /deep/ .content:hover {
        color: @uni-text-color-select;
        cursor: pointer;
      }
    }
    .sign {
      position: relative;
      margin-left: 32px;
      cursor: pointer;
      &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -17px;
        content: "";
        width: 11px;
        height: 14px;
        background: url("~@/assets/usercenter/sign.png");
        background-size: 11px 14px;
      }
    }
  }
}
</style>
